// Entry point for the build script in your package.json
import "../assets/javascripts/application.js";
import preventDoubleClicks from "../assets/javascripts/src/utils/preventDoubleClicks.js";
function submitSearch(ev, some) {
  const q = document.querySelectorAll("#query")[0];
  if (q && q.value.length > 3) {
    Rails.fire(this, "submit");
  } else {
    //console.log("nah q is ", q);
  }
}
window.preventDoubleClicks = preventDoubleClicks

document.addEventListener("DOMContentLoaded", () => {
  let searchForm = document.getElementById("search_by_query_form");
  if (searchForm) {
    searchForm.onchange = submitSearch;
  }
});
